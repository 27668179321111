body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cellStyle {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 30px;
  font-weight: 800;
}

#gameOverImage {
  opacity: 0.9;
  z-index: 4;
  height: 70%;
  max-width: 50%;
  margin: 0 50px;
  background: url("../Assets/SlothJaguar.png") 0px 80px no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  padding-top: 20px;
  margin-left: 150px;
}

.tryAgain {
  cursor: pointer;
  margin: 0 50px;
  border-radius: 7px;
  background: darkgreen;
  color: rgb(158, 255, 158);
  padding: 10px;
  font-weight: 700;
  max-width: 50%;
  margin-left: 150px;
}
