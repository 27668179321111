.sjylEq_mainContainer {
  background-color: #000;
  height: 60px;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
}

.sjylEq_leftContainer {
  justify-content: left;
  align-items: center;
  width: 30%;
  display: flex;
}

.sjylEq_centerContainer {
  justify-content: center;
  align-items: center;
  width: 40%;
  display: flex;
}

@media only screen and (width <= 600px) {
  .sjylEq_centerContainer {
    justify-content: center;
    align-items: center;
    width: 40%;
    display: none;
  }
}

.sjylEq_rightContainer {
  justify-content: end;
  align-items: center;
  width: 345px;
  display: flex;
}

.sjylEq_logo {
  scale: .65;
}

.sjylEq_logo2 {
  width: auto;
  max-height: 35px;
}

.sjylEq_logoTitle {
  color: #fff;
  font-family: Irish Grover, Helvetica;
  font-size: 30px;
}

.sjylEq_icon {
  width: 26px;
  height: 26px;
  position: relative;
}

.sjylEq_menuText {
  color: #d3d3d3;
  opacity: .5;
  letter-spacing: 1px;
  background-image: none;
  margin-left: 30px;
  font-family: Lato;
  font-size: 20px;
  font-weight: 400;
}

.sjylEq_menuText:hover {
  opacity: 1;
  color: #fff;
  text-underline-offset: 8px;
  text-decoration: underline;
}

.sjylEq_buycoin {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: Familjen Grotesk, Helvetica;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  position: relative;
}

.sjylEq_buttonoutline {
  background-image: linear-gradient(to right, #93012f, #af0707, #93012f);
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 157px;
  height: 30px;
  padding: 9px 15px;
  display: flex;
  position: relative;
}

.sjylEq_contactUs {
  color: #fff;
  border: 1px solid gray;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 25px;
  margin-right: 15px;
  font-family: Familjen Grotesk, Helvetica;
  font-size: clamp(.8rem, 1.5vw, .9rem);
  font-weight: 600;
  display: flex;
}

.sjylEq_contactUs:hover {
  background-color: #93012f;
  border: 1px solid #fff;
}

.TOhXzG_mainContainer {
  background-color: #000;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 35px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.TOhXzG_tickContainer {
  color: #ffffff80;
  border-style: solid;
  border-width: .5px;
  border-radius: 20px;
  max-width: 100px;
  margin-right: 15px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
}

@media only screen and (width >= 400px) {
  .TOhXzG_tickContainer {
    max-width: 100px;
    margin-right: 5px;
    display: flex;
  }
}

.TOhXzG_symbolText {
  color: #daa520;
  margin-right: 15px;
  font-size: 18px;
}

@media only screen and (width >= 400px) {
  .TOhXzG_symbolText {
    color: #daa520;
    margin-right: 5px;
    font-size: 16px;
  }
}

.TOhXzG_priceText {
  color: #fff;
  font-size: 18px;
}

@media only screen and (width >= 400px) {
  .TOhXzG_priceText {
    color: #fff;
    font-size: 16px;
  }
}

.mC0a0a_logo {
  width: 50px;
  height: 50px;
}

.mC0a0a_xlogo {
  width: 40px;
  height: 40px;
}

.mC0a0a_logotext {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  margin-left: 10px;
  font-family: Irish Grover, Helvetica;
  font-size: 40px;
  font-weight: 400;
  display: flex;
}

.mC0a0a_background {
  background-image: linear-gradient(#111, #151515, #111);
  justify-content: center;
  align-items: center;
  height: 75px;
  display: flex;
}

.mC0a0a_container {
  width: 80%;
  height: 60px;
  display: flex;
}

.mC0a0a_leftcontainer, .mC0a0a_middlecontainer {
  justify-content: center;
  align-items: center;
  width: 25%;
  display: flex;
}

.mC0a0a_rightcontainer {
  justify-content: center;
  align-items: center;
  width: 50%;
  display: flex;
}

.mC0a0a_text {
  color: #fff;
}

.mC0a0a_logo2 {
  width: auto;
  max-height: 50px;
}

.ollie-landing-page {
  background-color: #dbceb4;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 4200px;
  display: flex;
}

.ollie-landing-page .div {
  background-color: #dbceb4;
  width: 1280px;
  display: block;
  position: relative;
  overflow: hidden;
}

.ollie-landing-page .lovepik-com {
  object-fit: cover;
  width: 472px;
  height: 674px;
  position: absolute;
  top: 0;
  left: 658px;
}

.ollie-landing-page .frame {
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 742px;
  display: flex;
  position: absolute;
  top: 95px;
  left: 0;
}

.ollie-landing-page .frame-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 60px;
  width: 100%;
  display: flex;
  position: relative;
}

.ollie-landing-page .frame-3 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.ollie-landing-page .frame-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  display: flex;
  position: relative;
}

.ollie-landing-page .text-wrapper {
  color: var(--primary);
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  height: 49px;
  margin-top: -1px;
  font-family: Irish Grover, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.ollie-landing-page .p {
  color: var(--b);
  letter-spacing: 0;
  width: 722px;
  font-family: Familjen Grotesk, Helvetica;
  font-size: 80px;
  font-weight: 700;
  line-height: 80px;
  position: relative;
}

.ollie-landing-page .text-wrapper-2 {
  color: #6a6a6a;
  letter-spacing: 0;
  align-self: stretch;
  font-family: Roboto Condensed, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  position: relative;
}

.ollie-landing-page .div-wrapper {
  flex: none;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.ollie-landing-page .frame-5 {
  background-color: var(--primary);
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 187px;
  padding: 18px 32px;
  display: flex;
  position: relative;
}

.ollie-landing-page .icon-park-outline {
  width: 26px;
  height: 26px;
  position: relative;
}

.ollie-landing-page .buy-coin {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: Familjen Grotesk, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  position: relative;
}

.ollie-landing-page .overlap-group {
  background-image: url("rectangle-2.7db394ca.png");
  background-size: 100% 100%;
  border-bottom-right-radius: 200px;
  border-bottom-left-radius: 200px;
  width: 1280px;
  height: 160px;
  position: absolute;
  top: 0;
  left: 0;
}

.ollie-landing-page .img {
  width: 198px;
  height: 85px;
  position: absolute;
  top: 18px;
  left: 580px;
}

.ollie-landing-page .overlap-2 {
  width: 1108px;
  height: 815px;
  margin-top: 100px;
  position: absolute;
}

.ollie-landing-page .frame-6 {
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: 798px;
  display: flex;
  position: relative;
}

.ollie-landing-page .text-wrapper-3 {
  color: var(--primary);
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Irish Grover, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.ollie-landing-page .frame-7 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.ollie-landing-page .text-wrapper-4 {
  color: #6a6a6a;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Roboto Condensed, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  position: relative;
}

.ollie-landing-page .text-wrapper-5 {
  color: #6a6a6a;
  letter-spacing: 0;
  align-self: stretch;
  font-family: Roboto Condensed, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  position: relative;
}

.ollie-landing-page .group {
  width: 663px;
  height: 114px;
  position: absolute;
  top: 439px;
  left: 445px;
}

.ollie-landing-page .overlap-3 {
  border: .3px solid;
  border-color: var(--primary);
  background-color: #f4f4f4;
  border-radius: 10px;
  width: 661px;
  height: 114px;
  position: relative;
}

.ollie-landing-page .image-wrapper {
  background-color: var(--primary);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 60px;
  height: 60px;
  padding: 12px;
  display: flex;
  position: absolute;
  top: 27px;
  left: 27px;
}

.ollie-landing-page .image-2 {
  object-fit: cover;
  width: 52px;
  height: 44px;
  margin: -4px -8px;
  position: relative;
}

.ollie-landing-page .text-wrapper-6 {
  color: var(--secondary);
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Roboto Condensed, Helvetica;
  font-size: 26px;
  font-weight: 500;
  line-height: 26px;
  position: absolute;
  top: 44px;
  left: 112px;
}

.ollie-landing-page .overlap-group-wrapper {
  width: 1280px;
  height: 909px;
  position: absolute;
  top: 1694px;
  left: 0;
}

.ollie-landing-page .overlap-group-2 {
  height: 909px;
  position: relative;
}

.ollie-landing-page .ellipse {
  background-color: #d9d9d9;
  border-radius: 60.67px / 91px;
  width: 121px;
  height: 182px;
  position: absolute;
  top: 389px;
  left: 242px;
}

.ollie-landing-page .rectangle-wrapper {
  background-image: url("Chartbg.d2f9e924.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 1280px;
  height: 909px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.ollie-landing-page .rectangle {
  background-color: #000c;
  height: 909px;
}

.ollie-landing-page .frame-8 {
  flex-direction: column;
  align-items: center;
  gap: 60px;
  width: 100%;
  display: flex;
  position: absolute;
  top: 80px;
  left: 182px;
}

.ollie-landing-page .frame-9 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 22px;
  width: 100%;
  display: flex;
  position: relative;
}

.ollie-landing-page .text-wrapper-7 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  font-family: Irish Grover, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.ollie-landing-page .text-wrapper-8 {
  color: #fff;
  letter-spacing: 0;
  width: 458px;
  height: 44px;
  font-family: Roboto Condensed, Helvetica;
  font-size: 26px;
  font-weight: 500;
  line-height: 26px;
  position: relative;
}

.ollie-landing-page .frame-10 {
  align-items: center;
  gap: 161px;
  width: 1280px;
  display: block;
  position: absolute;
  top: 2655px;
  left: 0;
}

.ollie-landing-page .image-3 {
  object-fit: cover;
  border-radius: 20px;
  width: 600px;
  height: 413px;
  position: relative;
}

.ollie-landing-page .frame-11 {
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;
  width: 455px;
  display: flex;
  position: relative;
}

.ollie-landing-page .frame-12 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 36px;
  width: 100%;
  display: flex;
  position: relative;
}

.ollie-landing-page .text-wrapper-9 {
  color: #6a6a6a;
  letter-spacing: 0;
  align-self: stretch;
  margin-top: -1px;
  font-family: Roboto Condensed, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  position: relative;
}

.ollie-landing-page .buy-now {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Familjen Grotesk, Helvetica;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  position: relative;
}

.ollie-landing-page .frame-13 {
  align-items: center;
  gap: 161px;
  width: 981px;
  display: flex;
  position: absolute;
  top: 3297px;
  left: 150px;
}

.ollie-landing-page .frame-14 {
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;
  width: 798px;
  display: flex;
  position: relative;
}

.ollie-landing-page .frame-15 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  width: 1280px;
  margin-right: -821px;
  display: flex;
  position: relative;
}

.ollie-landing-page .frame-16 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 27px;
  width: 100%;
  display: flex;
  position: relative;
}

.ollie-landing-page .text-wrapper-10 {
  color: #6a6a6a;
  letter-spacing: 0;
  width: 987px;
  margin-top: -1px;
  font-family: Roboto Condensed, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  position: relative;
}

.ollie-landing-page .overlap-4 {
  background-color: var(--primary);
  width: 1920px;
  height: 126px;
  position: absolute;
  top: 3638px;
  left: 0;
}

.ollie-landing-page .frame-17 {
  justify-content: space-between;
  align-items: center;
  width: 1092px;
  display: flex;
  position: relative;
  top: 21px;
  left: 149px;
}

.ollie-landing-page .frame-18 {
  flex: none;
  align-items: center;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.ollie-landing-page .frame-19 {
  flex: none;
  align-items: center;
  gap: 14px;
  display: inline-flex;
  position: relative;
}

.ollie-landing-page .image-4 {
  width: 94px;
  height: 85px;
  position: relative;
}

.ollie-landing-page .text-wrapper-11 {
  color: #fff;
  letter-spacing: 0;
  width: fit-content;
  font-family: Irish Grover, Helvetica;
  font-size: 46px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.ollie-landing-page .frame-20 {
  width: 135px;
  position: relative;
}

.ollie-landing-page .all-right-reserved {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: Roboto Condensed, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.x {
  width: 50px;
  height: 50px;
}

.ollieContainer {
  width: 100%;
  height: 700px;
}

.whatisOllie {
  width: 100%;
  height: 500px;
}

.Ollieleft {
  float: left;
  width: 50%;
}

.Ollieright {
  float: right;
  width: 50%;
}

.Olliesweeper {
  color: var(--primary);
  letter-spacing: 0;
  white-space: nowrap;
  text-align: center;
  align-self: stretch;
  height: 49px;
  margin-top: -1px;
  font-family: Irish Grover, Helvetica;
  font-size: 50px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.boardcontainer {
  justify-content: center;
  align-items: center;
  display: flex;
}

.board {
  width: fit-content;
}

.retry {
  width: 100px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  pointer-events: none;
  height: 40vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: 20s linear infinite App-logo-spin;
  }
}

.App-header {
  color: #fff;
  background-color: #282c34;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  display: flex;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.cellStyle {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 30px;
  font-weight: 800;
  display: flex;
}

#gameOverImage {
  opacity: .9;
  z-index: 4;
  background: url("SlothJaguar.d8fdf1db.png") 0 80px / contain no-repeat;
  max-width: 50%;
  height: 70%;
  margin: 0 50px 0 150px;
  padding-top: 20px;
  position: relative;
}

.tryAgain {
  cursor: pointer;
  color: #9eff9e;
  background: #006400;
  border-radius: 7px;
  max-width: 50%;
  margin: 0 50px 0 150px;
  padding: 10px;
  font-weight: 700;
}

.HMOgaq_mainContainer {
  height: 500px;
}

.pp7itq_chart {
  border-radius: 25px;
  width: 1000px;
  height: 700px;
}

@media only screen and (width <= 1700px) {
  .pp7itq_chart {
    border-radius: 20px;
    width: 700px;
    height: 500px;
  }
}

.gu91eG_mainContainer {
  background-image: linear-gradient(#000, #2a2a33, #000);
  width: 100%;
  padding-top: 3%;
  padding-bottom: 3%;
  display: flex;
}

.gu91eG_leftContainer {
  width: 20%;
}

.gu91eG_middleContainer {
  width: 60%;
  display: flex;
}

.gu91eG_middleLeft {
  width: 50%;
  padding-right: 30px;
}

@media only screen and (width <= 1200px) {
  .gu91eG_middleLeft {
    width: 100%;
    padding: 15%;
  }
}

.gu91eG_middleRight {
  width: 50%;
}

@media only screen and (width <= 1200px) {
  .gu91eG_middleRight {
    width: 0%;
  }
}

.gu91eG_rightContainer {
  width: 20%;
}

.gu91eG_image {
  border-radius: 15px;
  max-width: 100%;
  height: auto;
}

@media only screen and (width <= 1200px) {
  .gu91eG_image {
    display: none;
  }
}

.gu91eG_title {
  color: #fff;
  font-family: Agency FB;
  font-size: clamp(1rem, 2vw + 1rem, 3rem);
}

.gu91eG_text {
  color: #fff;
  font-size: clamp(.5rem, 2vw + .3rem, 1.25rem);
}

.gu91eG_button {
  color: #fff;
  background-image: linear-gradient(to right, #93012f, #af0707, #93012f);
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: 160px;
  height: 20px;
  padding: 18px 32px;
  font-family: Familjen Grotesk, Helvetica;
  display: flex;
  position: relative;
}

.gu91eG_button:hover {
  scale: 1.1;
}

.fcWG9W_mainContainer {
  background-image: linear-gradient(#111, #333, #111);
  width: 100%;
  height: 100%;
  display: flex;
}

.fcWG9W_leftContainer {
  color: #fff;
  justify-content: initial;
  background-image: linear-gradient(#050505, #222, #050505);
  flex-direction: column;
  align-items: center;
  width: 20%;
  height: 1500px;
  display: flex;
}

@media only screen and (width <= 1100px) {
  .fcWG9W_leftContainer {
    display: none;
  }
}

.fcWG9W_coinCard {
  border: 1px solid #dcdcdc;
  border-radius: 20px;
  align-items: center;
  width: 280px;
  height: 70px;
  margin-bottom: 15px;
  display: flex;
}

.fcWG9W_coinLogo {
  width: 30px;
  height: 30px;
  margin-left: 15px;
  margin-right: 15px;
}

.fcWG9W_coinGraph {
  color: #fff;
}

.fcWG9W_coinName {
  width: 45%;
}

.fcWG9W_coinPrice {
  width: 30%;
  font-size: 20px;
  font-weight: 600;
  display: block;
}

.fcWG9W_middleContainer {
  color: #fff;
  justify-content: center;
  width: 60%;
  height: 100%;
  display: block;
}

@media only screen and (width <= 1100px) {
  .fcWG9W_middleContainer {
    width: 100%;
  }
}

.fcWG9W_rightContainer {
  color: #fff;
  background-image: linear-gradient(#050505, #222, #050505);
  justify-content: center;
  width: 20%;
  height: 100%;
  display: flex;
}

@media only screen and (width <= 1100px) {
  .fcWG9W_rightContainer {
    display: none;
  }
}

.fcWG9W_chartContainer {
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  padding: 10px;
  display: flex;
}

.fcWG9W_rssFeed {
  width: 300px;
  height: 1500px;
}

.Q_upVa_mainContainer {
  background-image: linear-gradient(to right, #111, #050505, #111);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  display: flex;
}

.Q_upVa_menuBar {
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 50px;
  display: flex;
}

.Q_upVa_link {
  color: #fff;
  opacity: .8;
  margin-left: 20px;
  margin-right: 20px;
  font-family: Lato;
  font-size: 16px;
}

.Q_upVa_link:hover {
  color: #fff;
  opacity: 1;
  text-underline-offset: 10px;
  text-decoration: underline;
  scale: 1.1;
}

.Q_upVa_button {
  color: #000;
  background-color: (0, 0, 0, 0);
}

._22kkq_mainContainer {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

._22kkq_cardContainer {
  color: #bdbdbd;
  opacity: .95;
  background-image: linear-gradient(to right, #111, #222);
  border-style: solid;
  border-width: 5px;
  border-radius: 20px;
  width: 250px;
  height: 250px;
  margin: 20px;
  padding: 10px;
  display: block;
}

._22kkq_cardContainer:hover {
  color: #fff;
  opacity: 1;
  cursor: pointer;
  scale: 1.1;
}

@media only screen and (width <= 600px) {
  ._22kkq_cardContainer {
    opacity: .95;
    background-image: linear-gradient(to right, #111, #222);
    border-style: solid;
    border-width: 5px;
    border-radius: 20px;
    width: 200px;
    height: 200px;
    margin: 20px;
    padding: 10px;
    display: block;
  }
}

._22kkq_image {
  object-fit: contain;
  border-radius: 20px;
  max-width: 100%;
  height: auto;
}

._22kkq_title {
  color: #fff;
  height: 50px;
  margin-top: 20px;
  font-size: 14px;
}

a {
  color: #fff;
}

._22kkq_button {
  background-image: linear-gradient(to right, #9f0101, #9b0f0f, #9f0101);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
  margin-top: 5px;
  display: flex;
}

@media only screen and (width <= 600px) {
  ._22kkq_button {
    background-image: linear-gradient(to right, #9f0101, #9b0f0f, #9f0101);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10px;
    margin-top: -3px;
    display: flex;
  }
}

.JEhr4W_mainContainer {
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.JEhr4W_cardContainer {
  color: #bdbdbd;
  background-image: linear-gradient(to right, #111, #222);
  border: 2px solid #add8e6;
  border-radius: 20px;
  align-items: center;
  max-width: 350px;
  height: 150px;
  margin: 10px;
  display: flex;
}

.JEhr4W_cardContainer:hover {
  border: 2px solid gold;
  scale: 1.05;
}

.JEhr4W_openGraph {
  border-radius: 18px;
  justify-content: center;
  align-items: center;
  max-width: 340px;
  max-height: 145px;
  display: flex;
}

.JEhr4W_chainId {
  color: #fff;
  margin-left: 5px;
  font-family: Lato;
  font-size: 24px;
}

.JEhr4W_description {
  color: #fff;
  margin-top: 5px;
  font-family: Lato;
  font-size: 12px;
}

.JEhr4W_tokenAddress {
  color: red;
  word-wrap: break-word;
  justify-content: center;
  margin-top: 10px;
  font-family: Lato;
  font-size: 14px;
  display: flex;
}

._9ppuUa_mainContainer {
  background-color: #242529;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

._9ppuUa_subContainer {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

._9ppuUa_titleContainer {
  background-color: #111;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 125px;
  display: flex;
}

._9ppuUa_titleText {
  color: #fff;
  font-size: 24px;
}

._9ppuUa_invitationText {
  color: red;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 25px;
}

._9ppuUa_contactUs {
  color: #fff;
  background-image: linear-gradient(to right, #93012f, #af0707, #93012f);
  border: 1px solid #93012f;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 30px;
  font-family: Familjen Grotesk, Helvetica;
  font-size: 20px;
  display: flex;
}

._9ppuUa_contactUs:hover {
  cursor: pointer;
  border: 1px solid #fff;
  scale: 1.05;
}

._9ppuUa_card {
  background: linear-gradient(#111, #222, #111);
  border: 1px solid #add8e6;
  border-radius: 10px;
  align-items: center;
  width: 275px;
  height: 150px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
}

._9ppuUa_card:hover {
  cursor: pointer;
  z-index: 1;
  background: #000;
  border: 1px solid red;
  scale: 1.06;
}

._9ppuUa_cardTitle {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin-left: 15px;
  margin-right: 10px;
  font-family: Lato;
  font-size: clamp(1rem, 2vw, 1.4rem);
  font-weight: 900;
  display: flex;
}

._9ppuUa_cardSubTitle {
  font-family: Lato;
  font-size: clamp(.6rem, 2vw, .8rem);
}

._9ppuUa_gameLogo {
  justify-content: center;
  align-items: center;
  width: 70px;
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 10px;
  display: flex;
}

._9ppuUa_note {
  margin-top: 50px;
  margin-bottom: 30px;
  font-family: Lato;
  font-size: clamp(.9rem, 2vw, 1.2rem);
}

.B4HtFa_mainContainer {
  background-color: #242529;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.B4HtFa_subContainer {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.B4HtFa_titleContainer {
  background-color: #111;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  font-size: 24px;
  display: flex;
}

.B4HtFa_card {
  background: linear-gradient(#111, #222, #111);
  border: 1px solid #add8e6;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 275px;
  height: 200px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
}

.B4HtFa_card:hover {
  cursor: pointer;
  background: #000;
  border: 1px solid #ff0;
  scale: 1.06;
}

.B4HtFa_cardTitle {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin-right: 10px;
  font-family: Lato;
  font-size: clamp(1rem, 2vw, 1.4rem);
  font-weight: 900;
  display: flex;
}

.B4HtFa_cardSubTitle {
  font-family: Lato;
  font-size: clamp(.6rem, 2vw, .8rem);
}

.B4HtFa_dexLogo {
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 10px;
  display: flex;
}

.B4HtFa_note {
  margin-top: 50px;
  margin-bottom: 30px;
  font-family: Lato;
  font-size: clamp(.9rem, 2vw, 1.2rem);
}

._5nu2EG_mainContainer {
  background-color: #242529;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
  display: flex;
}

._5nu2EG_title {
  font-family: Helvetica;
  font-size: 30px;
}

.cN_IlG_mainContainer {
  background-color: #242529;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.cN_IlG_subContainer {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.cN_IlG_titleContainer {
  background-color: #111;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  font-size: 24px;
  display: flex;
}

.cN_IlG_card {
  background: linear-gradient(#111, #222, #111);
  border: 1px solid #add8e6;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 275px;
  height: 175px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
}

.cN_IlG_card:hover {
  cursor: pointer;
  background: #000;
  border: 1px solid #ffa07a;
  scale: 1.06;
}

.cN_IlG_cardTitle {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin-right: 10px;
  font-family: Lato;
  font-size: clamp(1rem, 2vw, 1.4rem);
  font-weight: 900;
  display: flex;
}

.cN_IlG_cardSubTitle {
  font-family: Lato;
  font-size: clamp(.6rem, 2vw, .8rem);
}

.cN_IlG_dexLogo {
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 10px;
  display: flex;
}

.cN_IlG_note {
  margin-top: 50px;
  margin-bottom: 30px;
  font-family: Lato;
  font-size: clamp(.9rem, 2vw, 1.2rem);
}

.PKP2tW_mainContainer {
  background-color: #242529;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.PKP2tW_subContainer {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.PKP2tW_titleContainer {
  background-color: #111;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  font-size: 24px;
  display: flex;
}

.PKP2tW_card {
  background: linear-gradient(#111, #222, #111);
  border: 1px solid #add8e6;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 275px;
  height: 175px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
}

.PKP2tW_card:hover {
  cursor: pointer;
  background: #000;
  border: 1px solid #8a2be2;
  scale: 1.06;
}

.PKP2tW_cardTitle {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100px;
  margin-right: 10px;
  font-family: Lato;
  font-size: clamp(1rem, 2vw, 1.4rem);
  font-weight: 900;
  display: flex;
}

.PKP2tW_cardSubTitle {
  font-family: Lato;
  font-size: clamp(.6rem, 2vw, .8rem);
}

.PKP2tW_dexLogo {
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 10px;
  display: flex;
}

.PKP2tW_chainLogo {
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: auto;
  margin-left: 10px;
  display: flex;
}

.PKP2tW_note {
  margin-top: 50px;
  margin-bottom: 30px;
  font-family: Lato;
  font-size: clamp(.9rem, 2vw, 1.2rem);
}

.gQ8e0W_mainContainer {
  background-image: linear-gradient(#090909, #212121, #090909);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  display: flex;
}

.gQ8e0W_subContainer {
  justify-content: center;
  align-items: center;
  width: 80%;
  display: flex;
}

.gQ8e0W_leftContainer {
  width: 15%;
}

.gQ8e0W_middleContainer {
  width: 70%;
  display: flex;
}

.gQ8e0W_middleLeft {
  width: 50%;
}

@media only screen and (width <= 600px) {
  .gQ8e0W_middleLeft {
    display: none;
  }
}

.gQ8e0W_image {
  border: 5px solid #212121;
  border-radius: 25px;
  max-width: 100%;
  height: auto;
}

.gQ8e0W_middleRight {
  text-wrap: wrap;
  width: 50%;
}

@media only screen and (width <= 600px) {
  .gQ8e0W_middleRight {
    width: 100%;
  }
}

.gQ8e0W_title {
  color: #fff;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: Lato;
  font-size: 28px;
}

.gQ8e0W_text {
  color: #fff;
  word-break: break-word;
  font-size: clamp(1rem, 2vw, 1.5rem);
  line-height: 30px;
}

@media only screen and (width <= 600px) {
  .gQ8e0W_text {
    color: #fff;
    font-size: 14px;
  }
}

.gQ8e0W_rightContainer {
  width: 15%;
}

.XID_QG_mainContainer {
  background-color: #dbceb4;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 1280px;
  display: flex;
}

.XID_QG_title {
  font-size: 50px;
}

.ktD6Za_mainContainer {
  background-color: #dbceb4;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 4364px;
  display: flex;
}

.ktD6Za_priceTicks {
  color: #000;
  font-size: 20px;
}

.ktD6Za_urlimage {
  border-radius: 20px;
  margin-top: 10px;
}

.ktD6Za_newsTitle {
  color: #000;
  font-size: 24px;
}

.ktD6Za_container {
  margin-top: 30px;
}

.qBrLQa_mainContainer {
  background-color: #242529;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  height: auto;
  padding-top: 50px;
  display: flex;
}

.qBrLQa_leftHeader {
  width: 20%;
  height: 400px;
}

.qBrLQa_middleHeader {
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 400px;
  display: flex;
}

.qBrLQa_nftHeaderContainer {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.qBrLQa_nftHeaderImage {
  object-fit: fill;
  border: 1px solid #2b2a2a;
  border-radius: 20px;
  width: 1000px;
  height: 381.5px;
  transition: border-color 2s;
}

.qBrLQa_nftHeaderImage:hover {
  border-color: red;
}

.qBrLQa_subHeader {
  align-items: center;
  width: 95%;
  height: 200px;
  margin-top: -220px;
  margin-left: 20px;
  display: flex;
}

.qBrLQa_headerNormalImage {
  object-fit: contain;
  border: 1px solid #616060;
  border-radius: 20px;
  max-width: 180px;
  height: auto;
}

.qBrLQa_headerTitle {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
}

.qBrLQa_rightHeader {
  width: 20%;
  height: 400px;
}

.qBrLQa_menuHeader {
  height: 80px;
}

.qBrLQa_marketSelector {
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 50px;
  display: flex;
}

.qBrLQa_bodyContainer {
  justify-content: center;
  width: 100%;
  height: auto;
  padding-top: 50px;
  display: flex;
}

.qBrLQa_leftMenuBar {
  color: #fff;
  text-align: center;
  background-color: #242529;
  border-right: 1px solid #686852;
  flex-direction: column;
  align-items: center;
  width: 220px;
  height: 1000px;
  font-family: Lato;
  font-size: 14px;
  display: flex;
}

.qBrLQa_marketBtn {
  width: 120px;
  margin-bottom: 5px;
  font-family: Lato;
  font-size: 12px;
}

.qBrLQa_marketLogo {
  width: 20px;
  height: 20px;
}

.qBrLQa_selector {
  align-items: center;
  display: flex;
}

.qBrLQa_nftSection {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 84%;
  height: auto;
  display: flex;
}

.qBrLQa_bodyHeader {
  color: #fff;
  text-align: center;
  width: 100%;
  height: 50px;
  font-size: 30px;
  font-weight: 900;
}

.qBrLQa_cardContainer {
  color: #fff;
  background-image: linear-gradient(#222, #444, #111);
  border-style: solid;
  border-color: #333232;
  border-radius: 20px;
  width: 280px;
  height: 340px;
  margin: 10px;
  font-family: Lato;
  font-size: 20px;
  font-weight: 900;
  transition: transform .7s;
  display: block;
}

.qBrLQa_cardContainer:hover {
  cursor: pointer;
  border-color: #d9eef5;
  transform: scale(1.07);
}

.qBrLQa_bannerImage {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  width: 280px;
  min-height: 200px;
  max-height: 200px;
}

.qBrLQa_cardtitle {
  color: #fff;
  align-items: center;
  width: 90%;
  height: 50px;
  padding-left: 20px;
  font-family: Lato;
  font-size: 18px;
  font-weight: 900;
  display: flex;
}

.qBrLQa_subTitle {
  color: gray;
  height: 30px;
  font-size: 14px;
}

.qBrLQa_container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.qBrLQa_leftContainer, .qBrLQa_rightContainer {
  width: 50%;
  height: 100%;
  padding-left: 20px;
}

.qBrLQa_text {
  color: #fff;
  width: 100%;
  font-size: 16px;
  font-weight: 900;
  display: flex;
}

.VDGLeG_formContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  display: flex;
}

.VDGLeG_formSection {
  flex-wrap: wrap;
  width: 100%;
  height: 200px;
  display: flex;
}

.VDGLeG_sectionBreak {
  color: gray;
  border-bottom: 1px solid #000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  font-size: 14px;
  display: flex;
}

.VDGLeG_formSectionMessage {
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  display: flex;
}

.VDGLeG_input {
  border: 1px solid #000;
  border-radius: 20px;
  justify-content: center;
  width: 98%;
  height: 35px;
  padding-left: 2%;
  font-family: Lato;
  font-size: 16px;
  display: flex;
}

.VDGLeG_inputText {
  height: 20px;
  font-size: 20px;
  font-size: "Lato";
}

.VDGLeG_message {
  border: 1px solid #000;
  border-radius: 20px;
  flex-wrap: wrap;
  justify-content: center;
  width: 98%;
  height: 200px;
  padding-left: 2%;
  padding-right: 2%;
  font-family: Lato;
  font-size: 16px;
  display: flex;
}

.VDGLeG_submitBtn {
  color: #fff;
  background-image: linear-gradient(to right, #93012f, #af0707, #93012f);
  border: 1px solid #93012f;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 60px;
  font-family: Familjen Grotesk, Helvetica;
  font-size: 20px;
  display: flex;
}

.VDGLeG_submitBtn:hover {
  cursor: pointer;
  border: 1px solid #fff;
  scale: 1.05;
}

.VDGLeG_messageRes {
  margin-bottom: 10px;
  font-size: 20px;
}

.W1ADya_mainContainer {
  color: #fff;
  flex-direction: column;
  width: 50%;
  height: 90%;
  margin-left: 25%;
  padding: 5%;
  display: flex;
}

.W1ADya_title {
  margin-bottom: 10px;
  font-family: Lato;
  font-size: 48px;
}

.W1ADya_text {
  font-size: 20px;
}

.W1ADya_header {
  margin-bottom: 50px;
  padding: 3%;
}

.myIe6a_mainContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
}

.myIe6a_title {
  color: #fff;
  font-family: Lato;
  font-size: clamp(1.5rem, 3vw, 4rem);
  font-weight: 600;
}

.jrKEyq_mainContainer {
  background-image: linear-gradient(#000, #2a2a33, #000);
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 4364px;
  display: block;
}

.jrKEyq_title {
  color: #fff;
  font-size: 50px;
}

.jrKEyq_text {
  color: #fff;
  font-size: 24px;
}

.pedmea_mainContainer {
  background-color: #dbceb4;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 1280px;
  padding-left: 10%;
  padding-right: 10%;
  display: block;
}

.pedmea_secondContainer {
  text-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}

.pedmea_thirdContainer {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.pedmea_title {
  color: brown;
  font-size: 30px;
}

.pedmea_mineContainer {
  width: fit-content;
}

.oaAaRG_mainContainer {
  background-image: linear-gradient(#111, #333, #111);
  flex-flow: wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.oaAaRG_title {
  color: #fff;
  font-size: 24px;
}

.oaAaRG_cardContainer {
  color: #bdbdbd;
  background-image: linear-gradient(to right, #111, #222);
  border-style: solid;
  border-width: 5px;
  border-radius: 20px;
  align-items: center;
  width: 400px;
  height: 250px;
  margin: 10px;
  padding: 3px;
  display: block;
}

.oaAaRG_bannerImage {
  object-fit: contain;
  background-color: #222;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  max-width: 385px;
  max-height: 130px;
  font-size: 30px;
}
/*# sourceMappingURL=index.901ec133.css.map */
